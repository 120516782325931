<script setup>
    const props = defineProps({
        carouselCards: {
            type: Array,
            default: [],
        },
    });
    const { carouselCards } = toRefs(props);
    const carouselContainer = ref(null);
    const currentIndex = ref(0);
    const totalCards = carouselCards?.value?.length;
    const controlsInUse = ref(false);
    function forward() {
        controlsInUse.value = true;
        currentIndex.value >= totalCards - 1 ? (currentIndex.value = 0) : (currentIndex.value += 1);
        const pixelBase = (carouselContainer.value.itemsWrapperRef?.offsetWidth / totalCards);
        let currentPixels = pixelBase * currentIndex.value;
        carouselContainer.value.itemsWrapperRef.scrollTo({left: currentPixels});
        controlsInUse.value = false;
    }

    function backward() {
        controlsInUse.value = true;
        currentIndex.value <= 0 ? (currentIndex.value = totalCards - 1) : (currentIndex.value -= 1);
        const pixelBase = (carouselContainer.value.itemsWrapperRef?.offsetWidth / totalCards);
        let currentPixels = pixelBase * currentIndex.value;
        carouselContainer.value.itemsWrapperRef.scrollTo({left: currentPixels});
        controlsInUse.value = false;
    }

    function updateActive(index) {
        controlsInUse.value = true;
        let value = parseInt(index);
        currentIndex.value = value;

        const pixelBase = (carouselContainer.value.itemsWrapperRef?.offsetWidth / totalCards);
        let currentPixels = pixelBase * currentIndex.value;
        carouselContainer.value.itemsWrapperRef.scrollTo({left: currentPixels});
        controlsInUse.value = false;
    };

    function handleManualScroll(ev) {
        if (controlsInUse.value) return;
        const scrollLeft = ev?.target?.scrollLeft;
        const children = ev?.target?.children;
        const singleOffsetWidth = children?.[0]?.offsetWidth;
        const newIndex = parseInt(scrollLeft) / parseInt(singleOffsetWidth);

        if (controlsInUse.value) return;
        currentIndex.value = Math.ceil(newIndex);
    }

    onMounted(() => {
        const list = carouselContainer.value.itemsWrapperRef;
        list.addEventListener('scroll', (e) => {
            setTimeout(() => {
                handleManualScroll(e);
            }, 500);
        });
    });
</script>

<template>
    <div class="fragment simple-carousel-with-controls inner-container">
        <div class="carousel-container" :style="`--fraction: ${100 / totalCards}%`">
            <ProjectSimpleCarousel
                v-if="carouselCards?.length"
                :carouselWidth="`${carouselCards?.length * 100}%`"
                :stepLength="carouselContainer?.value?.itemsWrapperRef?.offsetWidth"
                :currentIndex="currentIndex"
                :number-of-slides="carouselCards?.length"
                ref="carouselContainer"
            >
                <FragmentsCardCaptionBox
                    v-for="(card, index) in carouselCards"
                    :card="card"
                    :cardIndex="index"
                    :key="index"
                    :style="`--offset: ${index}`"

                />
            </ProjectSimpleCarousel>
        </div>
        <div v-if="carouselCards?.length > 1" class="carousel-controls">
            <button @click="backward" class="back-arrow" title="Move the carousel back one slide">
                <ProjectSvg :type="`ArrowLeft`" />
            </button>

            <ul class="bullets secondary">
                <li v-for="bulletIndex in carouselCards?.length" :key="`bullet-${bulletIndex}`" :class="`bullet-${bulletIndex}`">
                    <button class="bullet" :class="{ active: currentIndex === bulletIndex - 1 }" @click="updateActive(bulletIndex - 1)" />
                </li>
            </ul>
            <button @click="forward" class="forward-arrow" title="Move the carousel forward one slide">
                <ProjectSvg :type="`ArrowRight`" />
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    .simple-carousel-with-controls {
        .carousel-controls {
            display: flex;
            align-items: center;
            justify-content: center;

            button.back-arrow {
                margin-right: 0.8rem;
            }
            button.forward-arrow {
                margin-left: 0.8rem;
            }

            .bullets {
                display: flex;
                gap: 0.5em;
                flex-wrap: wrap;
                max-width: calc(var(--vw) * 80);

                .bullet {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin: 0 6px;
                    border-radius: 50%;
                    background-color: var(--gray-100);
                    border: 1px solid var(--red);
                    transition: background-color 0.25s ease-in-out;
                    cursor: pointer;

                    @media (min-width: $mobile) {
                        margin: 0 15px;
                    }

                    &:hover,
                    &.active {
                        background-color: var(--red);
                        border: none;
                    }
                }
            }

            button {
                cursor: pointer;
            }
        }
    }
</style>